/*  ==========================================================================
    VARIABLES
    Collection of all variables

    INFO:
    - try to use variables as much as possible, it makes life easier
    ========================================================================== */

@import 'lib/colors';

$header_height_large: 140px;
$header_height_medium: 100px;
$header_height_small: 50px;


/*
#
# FONTS
#
*/

// font families
$ff_cantarell: 'Cantarell', sans-serif;
$ff_lora: 'Lora', sans-serif;


// font weights
$fw_regular: 400;
$fw_bold: 700;

/*
#
# TRANSITIONS
#
*/

// transition speed
$ts_slow: 1s;
$ts_middle: 0.6s;
$ts_fast: 0.3s;
$ts_superfast: 0.2s;