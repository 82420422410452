.headerButton{
  background-image: url("/typo3conf/ext/bn_typo_dist/Resources/Public/admin/img/backend/button_rot.png");
  position: absolute;
  z-index: 1002;
  bottom: 1%;
  left: 6%;
  height: 254px;
  width: 241px;
  background-repeat: no-repeat;
  background-size: 100%;

  .buttonText{
    position: absolute;
    top: 22%;
    left: 0%;
    text-align: center;
    font-size: 14px;
    text-transform: uppercase;
    color: #fff;
    padding: 25px;
    transform: rotate(10deg);
  }

  &:hover{
    cursor: pointer;
    background-image: url("/typo3conf/ext/bn_typo_dist/Resources/Public/admin/img/backend/button_beige.png");
  }
}

@media (max-width: 1024px) {
  .headerButton {
    bottom: -11%;
    left: 6%;
    background-size: 70%;

    .buttonText {
      top: 14%;
      left: -31%;
      font-size: 10px;
      word-spacing: 300px;
      padding: 0;
    }
  }
}

@media (max-width: 767px) {
  .headerButton {
    position: relative;
    left: 0;
    width: 100%;
    height: 4em;
    > div {
      height: 100%;
      margin: 0 1em;
      background-color: $clr_red;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    .buttonText {
      top: auto;
      left: auto;
      transform: inherit;
      font-size: 12px;
      margin: 0 3em;
      word-spacing: inherit;
    }
    &:hover {
      background-image: none;
    }
  }
}
