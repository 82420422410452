.total{
  text-align: center;
  display: block;
  text-transform: uppercase;
  font-size: 15px;
  margin-bottom: 15px;
}

.offerDetail {
  margin-top: 50px;

  @include list-icon;

  .titleMobile{
    display: none;
  }

  .left{
    img{
      height: 600px;
    }

    .price_nightsMobile{
      display: none;
    }
  }

  .slick-list {
    border: 1px solid $clr_gold;
  }

  .icon-arrow_right1 {
    left: 57%;
    position: absolute;
    top: -34px;
    text-align: center;
    cursor: pointer;
    z-index: 2;
  }

  .icon-arrow_left1 {
    right: 57%;
    left: initial !important;
    position: absolute;
    top: -34px;
    text-align: center;
    cursor: pointer;
    z-index: 2;
  }

  .right {
    display: flex;
    user-select: text;

    .offerInfo {
      margin-top: 25px;
      margin-left: 0;
      padding-left: 20px;
      margin-bottom: 50px;
      width: 100%;
      .title {
        font-family: Lora, sans-serif;
        font-size: 32px;
        color: $clr_red;
        text-transform: uppercase;
      }
      .subtitle{
        font-weight: $fw_bold;
        display: flex;
        color: $clr_black_fliestext;
        font-size: 18px;
      }
      .breadcrumbOffer{
        color: #6A7474;
        text-transform: uppercase;
        font-size: 12px;
        margin-bottom: 10px;
        a{
          text-decoration: none;
          text-transform: uppercase;
          color: #6A7474;
        }
      }
      .dates {
        color: $clr_black_fliestext;
        font-size: 18px;
        font-weight: $fw_bold;

        .date {
          font-weight: $fw_bold;
          &:after {
            content: " | ";
          }

          &:last-of-type:after {
            content: "";
          }
        }
      }

      .nights {
        color: $clr_black_fliestext;
        font-size: 18px;
        font-weight: $fw_bold;
        display: block;
      }

      .trennelement {
        color: $clr_red;
        font-size: 35px;
      }

      .description{
        line-height: 24px;
        @include table;

        .table_wrapper{
          overflow-x: scroll;
        }
      }

      .offer_link{
          text-decoration: none;
      }

      .buttons {
        text-align: center;
        margin-top: 40px;
        a{
          text-decoration: none;
        }

        .button {
          @include button;
          &.request {
            background-color: $clr_gray;
            color: $clr_white;
            border: 1px solid $clr_gray;
            width: 35%;

            &:hover {
              background-color: transparent;
              color: $clr_gray;
            }
          }

          &.book {
            background-color: $clr_turkey;
            color: $clr_white;
            border: 1px solid $clr_turkey;
            width: 35%;

            &:hover {
              background-color: transparent;
              color: $clr_turkey;
            }
          }
        }
      }
    }

    .price_nights {
      height: 142px;
      min-width: 142px;
      background-color: $clr_red;
      margin-left: 7.5%;
      margin-top: 50px;
      color: $clr_white;
      text-transform: uppercase;
      position: relative;

      .price_nights_wrapper{
        padding: 10px;
        position: absolute;
        bottom: 5px;

        .priceWrapper {
          display: flex;
          flex-direction: column;
          .from {
            font-size: 12px;
            font-family: Lora, sans-serif;
          }

          .price {
            font-size: 30px;
            font-family: Lora, sans-serif;
          }

          .euro{
            font-size: 16px;
          }

          .person {
            font-size: 11px;
            width: 116px;
            text-align: left;
          }
        }
      }
    }
  }
}

.nav_buttons{
  display: flex;
  justify-content: center;
  margin-top: 45px;
  a{
    text-decoration: none;
  }

  .button{
    @include button;

    &.inclusive{
      margin-left: 25px;
    }

    &:hover{
      @include buttonHover;
    }

    .icon{
      display: none;
      margin-left: 10px;
    }
  }
}

@media (max-width: 1024px) {
  .offerDetail{

    .icon-arrow_left1 {
      right: 60%;
    }

    .icon-arrow_right1 {
      left: 60%;
    }

    .right{
      .price_nights{
        margin-left: 5%;
      }
    }
  }
}

@media (max-width: 767px) {
  .offerDetail{

    .slick-list {
      border: 0;
    }

    .titleMobile{
      display: block;
      font-family: Lora, sans-serif;
      color: $clr_red;
      font-size: 20px;
      margin-bottom: 15px;
      text-transform: uppercase;
    }

    .icon-arrow_left1 {
      right: 75%;
    }

    .icon-arrow_right1 {
      left: 75%;
    }

    .left{
      img{
        height: 160px;
      }

      .price_nightsMobile {
        height: 60px;
        width: 130px;
        background-color: $clr_red;
        color: $clr_white;
        text-transform: uppercase;
        position: absolute;
        display: block;

        .price_nights_wrapper{
          padding: 10px;
          position: absolute;
          top: 0;
          left: 0;
          .from {
            font-size: 7px;
            font-family: Lora, sans-serif;
          }

          .price {
            font-size: 11px;
            font-family: Lora, sans-serif;
          }

          .euro{
            font-size: 8px;
          }

          .person {
            font-size: 7px;
            width: 116px;
            text-align: left;
          }
        }
      }

    }

    .right{
      .price_nights{
        display: none;
      }

      .offerInfo{
        margin: 0;
        padding: 0;
        .title{
          display: none;
        }
        .breadcrumbOffer{
          display: none;
        }
        .trennelement{
          display: none;
        }
        .description{
          margin-top: 7px;
        }
        .buttons{
          margin-top: 15px;
          .button {
            &.book{
              width: 100%;
              margin-top: 5px;
            }
            &.request{
              width: 100%;
              margin-top: 15px;
            }
          }
        }

        .subtitle{
          color: $clr_white;
          background: $clr_gold;
          padding: 10px;
          width: 100%;
          font-size: 14px;
        }
        .mobileBackground{
          background: $clr_gold;
          padding: 10px;
          span{
            font-size: 14px;
            color: $clr_white;
            display: block;
          }
          .date:after{
            content: "";
          }
        }
      }
    }

    .nav_buttons{
      display: block;
      text-align: center;
      margin-top: 15px;
        .button.inclusive{
          margin-left: 0;
          margin-top: 5px;
        }

      .button{
        border: 0;
        font-size: 13px;
        padding: 10px 40px;
        .icon{
          display: inline-block;
        }

        &:hover{
          background-color: transparent;
          color: $clr_gray;
          text-decoration: underline;
          background-position: right;
        }
      }
    }
  }
}

@media (max-width: 350px){
    .offerDetail{
      .nav_buttons{
        .button {
          font-size: 11px;
        }
      }
    }
}