.offerSlider {
  margin: 50px auto;
  max-width: 1920px;
  overflow: hidden;
  position: relative;

  .total{
    display: none;
  }


  .actSlide {
    position: absolute;
    left: 50%;
    transform: translate(-50%);
    font-size: 90px;
    z-index: 1;
    color: $clr_red;
    font-family: Lora, sans-serif;

    .trennelement {
      position: absolute;
      font-size: 37px;
      left: -30px;
      top: 90px;
    }
  }

  .offerButton {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 20px 0;

    .button {
      @include button;

      .icon{
        display: none;
      }

      &:hover {
        @include buttonHover;
      }
    }
  }

  .slider {
    padding-top: 40px;
    .slick-slide {
      padding: 5px;
    }
    .item {
      position: relative;
      background-color: #fff;
      float: left;

      .image {
        transition: opacity 1s;
        opacity: 1;
      }

      .priceMobile {
        display: none;
      }

      .price,
      .infoBox {
        display: none;
      }

      &:before,
      &:after {
        content: '';
        display: block;
        background-color: $clr_white;
        position: absolute;
        left: 0;
        width: 100%;
        z-index: 1;
        height: 0;
        transition: all 1s;
      }
      &:before {
        top: 0;
      }
      &:after {
        bottom: 0;
      }
    }

    .slick-current {
      .item {
        .price {
          position: absolute;
          top: -30px;
          right: 30px;
          background: $clr_red;
          padding: 15px;
          display: block;
          color: $clr_white;
          z-index: 2;

          .priceValue {
            font-size: 25px;
            font-family: Lora, sans-serif;
          }

          .from,
          .person {
            text-transform: uppercase;
            font-size: 13px;
          }
        }

        .infoBox {
          position: absolute;
          left: 0;
          top: 0;
          background-color: rgba(255, 255, 255, 0.8);
          padding: 60px 45px;
          margin: 10px;
          max-width: 50%;
          display: block;
          height: 95%;
          width: 50%;

          .topOffer {
            text-transform: uppercase;
            font-size: 14px;
            color: $clr_gray_dark
          }

          .bottom_content {
            display: flex;
            flex-direction: column;
            height: 100%;
            justify-content: space-between;
          }

          .description{
            @include list-icon;
            line-height: 28px;
            margin-top: 5px;
          }

          .title {
            color: $clr_red;
            font-size: 30px;
            font-family: Lora, sans-serif;
            text-transform: uppercase;
          }

          .date {
            font-weight: $fw_bold;
            color: $clr_gray_dark;
            font-size: 16px;
          }

          .nights {
            .night {
              font-weight: $fw_bold;
              color: $clr_gray_dark;
              font-size: 16px;
            }
          }

          .button_row {
            .button {
              @include button;
              margin-top: 10px;
              padding: 15px 100px;

              &:hover {
                @include buttonHover;
              }
            }
          }
        }
      }
    }

    &.slick-slider {
      .slick-list {
        overflow: visible;
        .slick-slide:not(.add_animation) {
          .item {
            .image {
              opacity: 0.3;
              transition: opacity 1s;
            }
            .text {
              opacity: 0;
            }

            &:before,
            &:after {
              height: 30px;
            }
          }
        }
      }
      .slider_arrow {
        position: absolute;
        bottom: -3px;
        color: $clr_gray_dark;
        z-index: 1;
        cursor: pointer;

        &.slider_arrow_prev {
          right: calc(50% - 1px);
        }
        &.slider_arrow_next {
          left: calc(50% - 1px);
        }
        &:hover{
          color: $clr_red;
        }
      }
    }
  }

  .icon-arrow_right1 {
    right: -4%;
    position: absolute;
    top: 50%;
    z-index: 1;
    font-size: 33px;
    color: $clr_gray_dark;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    &:hover{
      color: $clr_red;
      .slideNext {
        display: block;
        opacity: 1;
        transition: all 1s;
      }
    }
    .slideNext{
      opacity: 0;
      transition: all 1s;
    }
  }

  .icon-arrow_left1 {
    left: -4%;
    position: absolute;
    top: 50%;
    z-index: 1;
    font-size: 33px;
    color: $clr_gray_dark;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    &:hover{
      color: $clr_red;
      .slidePrev {
        display: block;
        opacity: 1;
        transition: all 1s;
      }
    }
    .slidePrev{
      opacity: 0;
      transition: all 1s;
    }
  }

  @include slick-dots;
  .slick-dots {
    position: absolute;
    bottom: 100%;
    left: 50%;
    transform: translate(-50%);
    z-index: 2;

    .slick-active {
      button {
        text-indent: 0 !important;
        color: #3F3E3E;
        opacity: 1;
      }
    }

    button {
      overflow: visible !important;
      text-indent: 15px !important;
      font-size: 12px;
      color: #3F3E3E;
      opacity: .4;
    }

    li {
      button {
        padding-top: 5px;
        line-height: 2;
      }
    }
  }

}

/* RESPONSIVE BEHAVIOR
 * --------------------------------------------------------------------------- */
@media (max-width: 1200px) {
  .offerSlider .slider .slick-current .item .infoBox{
    padding: 15px 20px;
    .title{
      font-size: 22px;
      line-height: 22px;
    }
    .date{
      font-size: 14px;
      margin-top: 10px;
    }
    .nights{
      .night{
        font-size: 14px;
      }
    }
  }
}

@media (max-width: 1024px) {
  .offerSlider {
    .actSlide {
      font-size: 56px;
      .number {
        position: absolute;
        left: 50%;
        transform: translate(-50%);
        top: 12px;
      }

      .trennelement {
        position: absolute;
        font-size: 25px;
        left: -50px;
        top: 70px;
      }
    }
    .slider {
      .slick-current {
        .item {

          .infoBox {
            padding: 40px 45px;
            width: 50%;

            .title {
              font-size: 17px;
              margin-top: 5px;
            }

            .date {
              margin-top: 5px;
            }

            .date,
            .nights {
              font-size: 15px;
            }

          }

          .price {
            top: 0;
            right: 0;
            z-index: 1;
          }
        }
      }
      &.slick-slider {
        .slick-list {
          overflow: hidden;
        }
      }
    }
    .description {
      display: none;
    }

    .icon-arrow_right1,
    .icon-arrow_left1 {
      display: none !important;
      background-color: transparent;
    }
  }
}

@media (max-width: 900px) {
  .offerSlider .slider .slick-current .item .infoBox{
    padding: 20px 20px;
  }
}

@media (max-width: 767px) {
  .offerSlider {
    padding: 0;
    .actSlide {
      font-size: 43px;
      .number {
        top: -22px;
      }

      .trennelement {
        font-size: 17px;
        left: -33px;
        top: 20px;
      }
    }

    .total{
      display: block;
    }

    .offerButton {
      padding: 0;
      .button{
        border: 0;
        .icon{
          display: inline-block;
          margin-left: 10px;
        }

        &:hover{
          background-color: transparent;
          color: $clr_gray;
          text-decoration: underline;
          background-position: right;
        }
      }
    }

    .slick-dots {
      display: none !important;
    }

    .icon-arrow_right1,
    .icon-arrow_left1 {
      display: block!important;
      top: -38px;
      color: #000;
      font-size: 21px;
    }

    .icon-arrow_right1{
      left: 75%;

      .slideNext{
        display: none !important;
      }
    }

    .icon-arrow_left1 {
      right: 75%;
      left: inherit;
      .slidePrev{
        display: none !important;
      }
    }

    .slider {
      padding: 0;

      .slick-slide{
        padding: 0;
      }

      .slick-current {
        .item {
          .infoBox {
            position: inherit;
            margin: 0;
            max-width: 100%;
            width: 100%;
            background-color: $clr_white;
            padding: 20px;

            .topOffer{
              font-size: 10px;
            }

            .button_row {
              .button {
                padding: 12px 60px;
              }
            }
          }
          .price {
            display: none;
          }

          .background {
            position: relative;
            .priceMobile {
              position: absolute;
              background: #a21c26;
              padding: 15px;
              display: block;
              color: #fff;
              bottom: 0;
              z-index: 1;
              top: inherit;
              left: 20px;
              right: inherit;

              .from,
              .person{
                text-transform: uppercase;
                font-family: Cantarell, sans-serif;
                font-size: 12px;
              }
              .priceValue{
                text-transform: uppercase;
                font-family: Lora, sans-serif;
                font-size: 19px;
              }
            }
          }
        }
      }
    }
  }
}