.galleryBox {
  margin-top: 50px;
  &.noSpace{
    margin-top: 15px;
  }
  .gallerySlider {
    @include clearfix();

    .slick-slide {
      margin: 0 15px;
    }

    .icon-arrow_right1 {
      right: 0;
      position: absolute;
      top: 40%;
      z-index: 1;
      font-size: 33px;
      cursor: pointer;

      .bgR{
        height: 60px;
        width: 50px;
        background: #fff;
        margin-top: -47px;
        margin-left: 15px;
      }

      &:hover{
        color: $clr_red;
      }
    }

    .icon-arrow_left1 {
      left: 17px;
      position: absolute;
      top: 40%;
      z-index: 1;
      font-size: 33px;
      cursor: pointer;

      .bgL{
        height: 60px;
        width: 50px;
        background: #fff;
        margin-top: -47px;
        margin-left: -20px;
      }

      &:hover{
        color: $clr_red;
      }
    }

    @include slick-dots;

    .img {
      position: relative;
      .icon-zoom {
        color: #fff;
        position: absolute;
        bottom: 10px;
        right: 10px;
        pointer-events: none;
        font-size: 20px;
      }
    }
  }

  .galleryButton{
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 20px 0;

    .button {
      @include button;

      &:hover{
        @include buttonHover;
      }
    }
    a{
      text-decoration: none;
    }
  }
}

@media (max-width: 767px){
  .galleryBox {
    padding-right: 0 !important;
    .gallerySlider {
      .slick-slide {
        margin: 0;
      }

      .img {
        float: left;
        width: calc(85vw - 40px) !important;
        padding: 0 5px;
      }

      .icon-arrow_right1,
      .icon-arrow_left1{
        display: none !important;
      }
    }
  }
}
