//Hide on Detail and Request Page
#uid52,
#uid53,
#uid57,
#uid10,
#uid11,
#uid12,
#uid13,
#uid14,
#uid8,
#uid39{
  .quickrequest {
    display: none;
  }
}


.quickrequest {
  position: fixed;
  bottom: 0;
  width: 100%;
  height: 0;
  background-color: $clr_black_fliestext;
  z-index: 1040;
  transition: 1s;
  padding: 0 40px;
}

@media (max-width: 1024px) {
  .quickrequest {
    display: none;
  }
}