.quote{
  text-align: center;
  margin-top: 50px;
  max-width: 600px;

  .text{
    color: $clr_red;
    font-size: 25px;
    margin-top: 25px;
  }

  .borderBottom,
  .borderTop{
    color: $clr_red;
    font-size: 35px;
  }
}

@media (max-width: 1024px){
  .quote{
    .text {
      font-size: 16px;
      max-width: 240px;
      margin: 15px auto 0px;
    }
  }
}

@media (max-width: 767px){
  .quote{
    margin: 25px 0;
    .text {
      font-size: 15px;
    }

    .borderBottom,
    .borderTop{
      font-size: 25px;
    }
  }
}