.rs-review {
  display: flex;
  justify-content: center;
  margin: 80px 0;
  text-align: center;

  .stars {
    margin: 12px 0;
    .rsicon {
      color: $clr_red_dark;
      font-size: 1.8em;
    }
  }

  a {
    text-decoration: none;
    color: $clr_red;
    &:hover {
      text-decoration: underline;
    }
  }
}