.textImageBox{
  display: flex;
  margin-top: 50px;

  a{
    color: $clr_red;
    text-decoration: none;
    &:hover{
      color: $clr_gold;
    }
  }

  @include list-icon;

  .left{
    //display: flex;
    //flex-wrap: wrap;
    //justify-content: flex-end;
    //align-items: center;
    width: 40%;

    img:first-of-type{
      height: auto;
      width: 100%;
    }

    img{
      height: auto;
      width: 70%;
      float: right;
      margin-top: 20px;
    }
  }

  .right{
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    margin-left: 20px;
    width: 60%;

    img{
      height: auto;
      width: 280px;
    }

    .textWrapper{
      background: rgba(173, 152, 129, 0.15);
      margin-top: 20px;
      padding: 60px;
      min-width: 100%;
      min-height: 300px;

      .title{
        color: $clr_gold;
        font-size: 22px;
        margin-bottom: 30px;
        text-transform: uppercase;
      }

      .text{
        line-height: 28px;
      }

      .button{
        @include button;
        &:hover{
          @include buttonHover;
        }
      }
    }
  }
}

//RESPONSIVE
@media (max-width: 1024px){
  .textImageBox{
    display: inherit;

    .left{
      display: flex;
      justify-content: space-around;
      width: 100%;
      img:first-of-type,
      img{
        height: auto;
        width: 49%;
        float: none;
        margin-top: 0;
      }
    }

    .right{
      margin-left: 0;
      width: 100%;

      img{
        display: none;
      }

      .textWrapper{
        padding: 30px;
        min-height: auto;

        .title{
          font-size: 18px;
          margin-bottom: 10px;
        }
        .text{
          font-size: 15px;
          line-height: 25px;
        }
      }
    }
  }
}

@media (max-width: 767px){
  .textImageBox{
    li:before{
      top: 0;
    }
    .right{
      .textWrapper{
        padding: 12px;
        margin-top: 10px;

        .title{
          font-size: 15px;
        }
        .text{
          font-size: 12px;
          line-height: 19px;
        }
      }
    }
    .left{
      justify-content: space-between;
      img:first-of-type,
      img{
        height: 92px;
      }
    }
  }
}