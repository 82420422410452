/*  ==========================================================================
    COLORS
    List all colors concerning your project here
    ========================================================================== */

$white: #FFF;
$black: #000;


// main colors
$clr_white: #fff;
$clr_black: #000;
$clr_black_fliestext: #3F4B50;
$clr_gray: #707070;
$clr_gray_dark: #3F3E3E;
$clr_red: #A21C26;
$clr_red_dark: #8E1520;
$clr_gold: #AD9881;
$clr_white_dark: #F5F5F5;
$clr_turkey: #28B1AB;





// custom colors
