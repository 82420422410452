.seasonSwitcher{
  display: flex;
  justify-content: center;
  margin-top: 65px;
  .spacer{
    &:after{
      content: "";
      border-left: 1px solid #6A7474;
      height: 85px;
      position: absolute;
      margin-top: -4px;
    }
  }
  .winter,
  .sommer{
    padding: 0 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    .title{
      text-transform: uppercase;
      margin-top: 17px;
      color: #6A7474;
    }
    .icon{
      font-size: 35px;
      color: #6A7474;
    }
    &:hover{
      cursor: pointer;
    }
    &.active{
      .title{
      color: $clr_gray_dark;
      }
      .icon{
        color: $clr_red;
      }
    }
  }
}

.noFilterResult{
  text-align: center;
  margin-top: 65px;
}

.offer_box {
  display: flex;
  margin-top: 50px;
  border: 1px solid $clr_gold;
  position: relative;

  @include list-icon;

  &.mirror {
    flex-direction: row-reverse;

    .price_nights {
      .price_nights_wrapper {
        right: 40%;
        transform: translateX(50%);
        left: inherit;
      }
    }

    .right {
      padding: 35px 45px;
      padding-right: 100px;
    }
  }

  .price_nights {
    .price_nights_wrapper {
      position: absolute;
      width: 142px;
      height: 142px;
      background: #a21c26;
      top: 30%;
      left: 40%;
      text-transform: uppercase;
      color: #fff;
      transform: translateX(-50%);

      .priceWrapper {
        position: absolute;
        bottom: 20px;
        text-align: left;
        padding-left: 10px;
        display: flex;
        flex-direction: column;
      }

      .from {
        font-size: 15px;
        font-family: Lora, sans-serif;
      }

      .price {
        font-size: 20px;
        font-family: Lora, sans-serif;
      }

      .euro{
        font-size: 16px;
      }

      .person {
        font-size: 12px;
        width: 116px;
        text-align: left;
      }
    }
  }

  .left {
    width: 40%;

    img {
      height: 100%;
    }
  }

  .right {
    width: 60%;
    padding: 35px 45px;
    padding-left: 100px;

    .title {
      color: $clr_gray_dark;
      font-size: 24px;
      text-transform: uppercase;
    }

    .nights {
      color: $clr_gray;
      font-size: 20px;
      text-transform: uppercase;
    }

    .dates {
      color: $clr_gray;
      font-size: 20px;
      display: block;

      .date {
        &:after {
          content: " | ";
        }

        &:last-of-type:after {
          content: " ";
        }
      }
    }

    .description {
      font-size: 16px;
      margin-top: 15px;
      margin-bottom: 15px;
      line-height: 27px;
    }

    .offer_link {
      text-decoration: none;
      text-transform: uppercase;
      color: $clr_gray_dark;

      .icon {
        margin-left: 10px;
      }
      &:hover{
        color: $clr_red;
        .icon {
          color: $clr_red;
        }
      }
    }

    .buttons {
      margin-top: 15px;

      a {
        text-decoration: none;
      }

      .button {
        @include button;

        &.request {
          background-color: $clr_gray;
          color: $clr_white;
          border: 1px solid $clr_gray;
          width: 49%;

          &:hover {
            background-color: transparent;
            color: $clr_gray;
          }
        }

        &.book {
          background-color: $clr_turkey;
          color: $clr_white;
          border: 1px solid $clr_turkey;
          width: 49%;

          &:hover {
            background-color: transparent;
            color: $clr_turkey;
          }
        }
      }
    }
  }
}

@media (max-width: 1024px) {
  .offer_box {
    .right {
      .buttons {
        .button {
          padding: 15px 20px;
        }
      }
    }
  }
}

@media (max-width: 767px) {
  .seasonSwitcher{
    margin-top: 30px;
    margin-bottom: 70px;
    box-shadow: 0 4px 8px -2px grey;
    height: 50px;
    .title{
      display: none;
    }
    .icon{
      font-size: 30px !important;
    }
    .spacer{
      &:after{
        height: 37px;
        margin-top: 7px;
      }
    }
  }

  .offer_box {
    display: block;

    .left {
      width: 100%;
    }

    .right {
      width: 100%;
      padding: 10px;

      .title{
        font-size: 16px;
      }

      .dates{
        margin-top: 10px;
        font-size: 14px;
        .date{
          display: block;
          &:after{
            content: "";
          }
        }
      }
      .description{
        display: none;
      }

      .nights{
        font-size: 14px;
      }

      .offer_link{
        display: block;
        width: 100%;
        padding: 10px 40px !important;
        border: 1px solid $clr_gray;
        margin-top: 10px;
        @include button;
        text-align: center;
        .icon{
          display: none;
        }
        &:hover{
          color: $clr_white;
          background-color: $clr_gray;
        }
      }
      .buttons{
        margin-top: 5px;
        .button{
          padding: 11px 40px !important;
        }
        .book{
          display: none;
        }
        .request{
          width: 100% !important;
          background-color: $clr_gold !important;
          border: 1px solid $clr_gold !important;
          &:hover{
            background-color: transparent !important;
            color: $clr_gold !important;
          }
        }
      }
    }

    .price_nights {
      .price_nights_wrapper {
        width: 130px;
        height: 55px;
        top: -33px;
        left: 50%;

        .priceWrapper {
          bottom: 14px;
          display: block;
        }

        .from {
          font-size: 7px;
        }

        .price {
          font-size: 12px;
        }

        .euro {
          font-size: 9px;
        }

        .person {
          font-size: 7px;
        }
      }
    }

    &.mirror {
      .right {
        padding: 10px;
      }

      .price_nights {
        .price_nights_wrapper {
          right: 50%;

          .priceWrapper {
            bottom: 14px;
          }

          .from {
            font-size: 7px;
          }

          .price {
            font-size: 12px;
          }

          .person {
            font-size: 7px;
          }
        }
      }
    }

  }
}