/*  ==========================================================================
    FOOTER
    Styling for the lowest part of the website
    ========================================================================== */

@media (min-width: 1024px) {
  .bn-footer {
    margin-top: 50px;
    margin-bottom: 120px;

    .newsletterForm {
      .newsletterTitle {
        text-transform: uppercase;
      }
    }

    .footerTop {
      display: flex;
      margin-top: 40px;

      .left {
        width: 32%;
        padding-right: 5px;

        .box {
          width: 46%;
          height: 135px;
          float: left;
          display: flex;
          justify-content: center;
          align-items: center;
          color: white;
          font-size: 35px;
          flex-direction: column;

          .title {
            display: none;
            font-size: 14px;
            color: $clr_red;
            text-transform: uppercase;
            font-weight: bold;
          }

          &:hover {
            .title {
              display: block;
              margin-top: 10px;
            }

            background-color: transparent !important;
            color: $clr_red;
          }

          &.download {
            background-color: #A5A7A8;
            margin: 0 10px 10px 0;
          }

          &.webcam {
            background-color: $clr_gray_dark;
            margin: 0 0 10px 10px;
          }

          &.voucher {
            background-color: $clr_gold;
            clear: both;
            margin: 10px 10px 0 0;
          }

          &.gallery {
            background-color: $clr_red;
            margin: 10px 0 0 10px;
          }
        }
      }

      .center {
        width: 35%;
        padding-right: 20px;

        .weather {
          height: 290px;
          background-color: $clr_black_fliestext;

          .dayforecast.today {
            display: flex;
            justify-content: center;
            margin-top: 30px;

            .rightSide {
              display: flex;
              flex-direction: column;

              .date {
                color: $clr_white;
              }

              .tempmin,
              .tempmax {
                font-size: 30px;
                font-family: $ff_lora;
                color: $clr_white;
              }
            }

            .leftSide {
              .weather-symbol {
                font-size: 100px;
                color: $clr_white;
              }
            }
          }

          .dayforecast.tomorrow {
            margin-top: 30px;
          }

          .second,
          .third {
            color: $clr_white;
            text-align: center;

            span {
              &::after {
                content: " | ";
              }

              &:last-of-type {
                &::after {
                  content: "  ";
                }
              }
            }
          }

          .header {
            text-transform: uppercase;
            color: $clr_white;
            text-align: center;
            padding-top: 30px;
          }
        }
      }

      .right {
        width: 33.5%;
        overflow: hidden;

        .lable {
          position: absolute;
          z-index: 1;
          color: #fff;
          text-transform: uppercase;
          left: 50%;
          transform: translate(-50%);
          top: 25px;
          text-align: center;
          width: 100%;
        }

        .image {
          height: 290px;
          width: 100%;
          position: relative;

          .button {
            position: absolute;
            bottom: 25px;
            left: 50%;
            background: transparent;
            transform: translate(-50%);
            border: 1px solid $clr_white;
            text-transform: uppercase;
            padding: 12px 64px;
            color: $clr_white;
            cursor: pointer;
            text-align: center;

            &:hover {
              background: #fff;
              color: $clr_gray_dark;
            }
          }

          img {
            min-height: 100%;
            min-width: 100%;
            width: 100%;
            height: auto;
            max-width: none;
            max-height: none;
            display: block;
            position: relative;
          }
        }
      }
    }

    .bn-legal-menu {
      text-align: center;
      color: #626262;
      font-size: 14px;
      text-decoration: none;
      margin-top: 35px;

      a {
        color: #626262;
        font-size: 14px;
        text-decoration: none;

        &::after {
          content: " | ";
        }

        &:hover {
          color: $clr_gold;
        }
      }

    }

    .bn_link {
      display: flex;
      justify-content: center;
      text-align: center;
      margin-top: 2em;
      font-size: 1em;

      > a {
        display: block;
        width: 6.5em;

        img {
          width: 100%;
          height: auto;
        }
      }
    }

    .partnerLogos {
      display: flex;
      justify-content: center;
      margin: 40px 0;

      img {
        width: 140px;

      }

      .logo {
        padding: 0 20px;
        border-left: 1px solid #000;
        height: 80px;
        position: relative;
        box-sizing: border-box;

        &:first-of-type {
          border: none;
          padding-left: 0;
        }
      }
    }
  }
}

/* RESPONSIVE BEHAVIOR
 * --------------------------------------------------------------------------- */

@media (max-width: 1024px) {
  .bn-footer {
    margin-top: 50px;

    .newsletterForm {
      .newsletterTitle {
        text-transform: uppercase;
      }
    }

    .footerTop {
      margin-top: 40px;

      .left {
        width: 50%;
        float: left;
        padding-right: 10px;

        .box {
          width: 50%;
          height: 145px;
          float: left;
          display: flex;
          justify-content: center;
          align-items: center;
          color: white;
          font-size: 35px;
          flex-direction: column;

          .title {
            display: none;
            font-size: 14px;
            color: $clr_red;
            text-transform: uppercase;
            font-weight: bold;
          }

          &:hover {
            .title {
              display: block;
              margin-top: 10px;
            }

            background-color: transparent !important;
            color: $clr_red;
          }

          &.download {
            background-color: #A5A7A8;
            border-right: 10px solid $clr_white;
            border-bottom: 10px solid $clr_white;
            //margin: 0 10px 10px 0;
          }

          &.webcam {
            background-color: $clr_gray_dark;;
            border-left: 10px solid $clr_white;
            border-bottom: 10px solid $clr_white;
            //margin: 0 0 10px 10px;
          }

          &.voucher {
            background-color: $clr_gold;
            border-top: 10px solid $clr_white;
            border-right: 10px solid $clr_white;
            //margin: 10px 10px 0 0;
          }

          &.gallery {
            background-color: $clr_red;
            border-top: 10px solid $clr_white;
            border-left: 10px solid $clr_white;
            //margin: 10px 0 0 10px;
          }
        }
      }

      .center {
        width: 50%;
        float: right;

        .weather {
          height: 290px;
          background-color: $clr_black_fliestext;

          .dayforecast.today {
            display: flex;
            justify-content: center;
            margin-top: 30px;

            .rightSide {
              display: flex;
              flex-direction: column;

              .date {
                color: $clr_white;
              }

              .tempmin,
              .tempmax {
                font-size: 20px;
                font-family: $ff_lora;
                color: $clr_white;
              }
            }

            .leftSide {
              .weather-symbol {
                font-size: 100px;
                color: $clr_white;
              }
            }
          }

          .dayforecast.tomorrow {
            margin-top: 30px;
            font-size: 12px;
          }

          .dayforecast.dayforecast0 {
            font-size: 12px;
            margin-top: 2px;
          }

          .second,
          .third {
            color: $clr_white;
            text-align: center;

            span {
              &::after {
                content: " | ";
              }

              &:last-of-type {
                &::after {
                  content: "  ";
                }
              }
            }
          }

          .header {
            text-transform: uppercase;
            color: $clr_white;
            text-align: center;
            padding-top: 30px;
          }
        }
      }

      .right {
        clear: both;
        padding-top: 10px;

        .lable {
          position: absolute;
          z-index: 1;
          color: #fff;
          text-transform: uppercase;
          left: 50%;
          transform: translate(-50%);
          top: 25px;
          text-align: center;
          width: 100%;
        }

        .image {
          position: relative;
        }

        .button {
          position: absolute;
          bottom: 25px;
          left: 50%;
          background: transparent;
          transform: translate(-50%);
          border: 1px solid $clr_white;
          text-transform: uppercase;
          padding: 12px 40px;
          color: $clr_white;
          cursor: pointer;

          &:hover {
            background: #fff;
            color: $clr_gray_dark;
          }
        }
      }
    }

    .bn-legal-menu {
      text-align: center;
      color: #626262;
      font-size: 14px;
      text-decoration: none;
      margin-top: 35px;

      a {
        color: #626262;
        font-size: 14px;
        text-decoration: none;

        &::after {
          content: " | ";
        }

        &:hover {
          color: $clr_red;
        }
      }

    }

    .bn_link {
      display: flex;
      justify-content: center;
      text-align: center;
      margin-top: 2em;
      font-size: 1em;

      > a {
        display: block;
        width: 6.5em;

        img {
          width: 100%;
          height: auto;
        }
      }
    }

    .partnerLogos {
      display: flex;
      justify-content: center;
      margin: 40px 0;
      flex-wrap: wrap;

      img {
        width: 140px;

      }

      .logo {
        padding: 0 20px;
        border-left: 0;
        height: 80px;
        position: relative;
        box-sizing: border-box;
      }
    }
  }
}

@media (max-width: 767px) {
  .bn-footer {
    margin-top: 25px;

    .partnerLogos {
      margin: 20px 0;
    }

    .newsletterForm {
      .newsletterTitle {
        text-align: center;
      }
    }

    .bn_link {
      display: flex;
      justify-content: center;
      text-align: center;
      margin-top: 2em;
      font-size: 1em;

      > a {
        display: block;
        width: 6.5em;

        img {
          width: 100%;
          height: auto;
        }
      }
    }

    .footerTop {
      .left {
        width: 100%;
        padding-right: 0;

        .box {
          height: 90px;

          &.download {
            border-right: 5px solid $clr_white;
            border-bottom: 5px solid $clr_white;
          }

          &.webcam {
            border-left: 5px solid $clr_white;
            border-bottom: 5px solid $clr_white;
          }

          &.voucher {
            border-top: 5px solid $clr_white;
            border-right: 5px solid $clr_white;
          }

          &.gallery {
            border-top: 5px solid $clr_white;
            border-left: 5px solid $clr_white;
          }
        }
      }

      .center {
        width: 100%;
        padding-top: 10px;

        .weather {
          height: 260px;

          .dayforecast.tomorrow {
            margin-top: 15px;
          }
        }
      }

      .right {
        clear: both;
        padding-top: 10px;
        overflow: hidden;

        .button {
          padding: 10px 10px;
        }
      }
    }

    .middleFooter {
      .bn-legal-menu {
        margin: 20px 0;
        font-size: 10px;
      }

      .bn-legal-menu a {
        font-size: 10px;
      }
    }
  }
}