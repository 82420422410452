/*  ==========================================================================
    BANNER
    ========================================================================== */

.banner {
  margin-top: 50px;
}


/* RESPONSIVE BEHAVIOR
 * --------------------------------------------------------------------------- */

@media (max-width: 1024px){
.banner {
  margin-top: 25px;
}
}

@media (max-width: 767px){

}
