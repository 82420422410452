.erlebnisSlider{
  margin-top: 50px;

  .icon-arrow_right1 {
    right: 40px;
    position: absolute;
    top: 50%;
    z-index: 1;
    font-size: 33px;
    cursor: pointer;

    .bgR{
      height: 60px;
      width: 35px;
      background: #fff;
      margin-top: -47px;
      margin-left: 15px;
    }

    &:hover{
      color: $clr_red;
    }
  }

  @include slick-dots;

  .icon-arrow_left1 {
    left: 40px;
    position: absolute;
    top: 50%;
    z-index: 1;
    font-size: 33px;
    cursor: pointer;

    .bgL{
      height: 60px;
      width: 50px;
      background: #fff;
      margin-top: -47px;
      margin-left: -20px;
    }

    &:hover{
      color: $clr_red;
    }
  }

  .personImage{
    display: flex;
    justify-content: center;
    position: relative;
    align-items: center;
    margin-bottom: -57px;
    img{
      width: 106px;
      height: 106px;
      display: block;
      border-radius: 50%;
    }
  }
  
  .textimagebox{
    display: inline-block;
    background-color: #eeeae5;

    .left{
      width: 30%;
      float: left;
      display: flex;
      img{
        padding: 30px 0;
      }
    }
    .right{
      width: 70%;
      float: right;
      padding: 80px 60px 10px 60px;

      .button {
        @include button;
        margin-bottom: 15px;
        &:hover {
          @include buttonHover;
        }
      }

      .title{
        text-transform: uppercase;
        color: $clr_gold;
        font-size: 22px;
        padding-bottom: 5px;
      }
    }
  }

  .textimagebox.noImage{
    .right{
      width: 100%;
      text-align: center;
    }
  }
}

@media (max-width: 1024px){
  .erlebnisSlider{
    .textimagebox{
      .left{
        display: none;
      }
      .right{
        width: 100%;
        text-align: center;
      }
    }
  }
}

@media (max-width: 767px) {
  .erlebnisSlider{
    .icon-arrow_right1 {
      right: 20px;
    }

    .icon-arrow_left1{
      left: 20px;
    }
  }
}