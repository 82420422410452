.roomsList {
  @include slick-dots;

  ul:not(.slick-dots){
    list-style: none;

    li{
      display: inline;
      &:after{
        content: ", ";
      }
      &:last-of-type:after{
        content: " ";
      }
      br{
        display: none;
      }
    }
  }

  .slick-dots {
    position: absolute;
    bottom: 12px;
    right: 5px;

    li {
      background-color: hsla(0, 0%, 100%, .5);
      button {
        background-color: $clr_white;
      }
    }
  }

  .priceTabMobile{
    display: none;
  }

  .icon-arrow_right1 {
    right: 0;
    position: absolute;
    top: 50%;
    z-index: 1;
    font-size: 33px;
    cursor: pointer;

    .bgR{
      height: 60px !important;
      width: 35px;
      background: #fff;
      margin-top: -47px;
      margin-left: 15px;
    }

    &:hover{
      color: $clr_red;
    }
  }

  .icon-arrow_left1 {
    left: 0;
    position: absolute;
    top: 50%;
    z-index: 1;
    font-size: 33px;
    cursor: pointer;

    .bgL{
      height: 60px !important;
      width: 35px;
      background: #fff;
      margin-top: -47px;
      margin-left: 0;
    }

    &:hover{
      color: $clr_red;
    }
  }

  .room {
    display: flex;
    margin-top: 50px;

    .left {
      width: 50%;
      position: relative;

      .img-slider,
      .slick-track,
      .slick-slider div,
      .slider_item{
        height: 100%;
        max-height: 335px;
      }

      img {
        height: 100%;
        max-height: 335px;
      }

      .slick-slide {
        height: 100%;
      }

      .priceTab {
        background-color: $clr_red;
        position: absolute;
        top: 0;
        left: 0;
        width: 181px;
        height: 70px;
        z-index: 1;
        color: $clr_white;
        text-transform: uppercase;
        padding: 5px 15px;
        display: flex;
        align-items: center;
        justify-content: center;

        .from {
          font-size: 12px;
          font-family: Lora, sans-serif;
        }

        .price {
          font-size: 21px;
          font-family: Lora, sans-serif;
        }

        .euro{
          font-size: 14px;
        }

        .person {
          font-size: 12px;
          width: auto;
          text-align: left;
          display: block;
        }
      }
      .icon-zoom{
        position: absolute;
        bottom: 20px;
        left: 18px;
        z-index: 1;
        font-size: 22px;
        color: #fff;
        pointer-events: none;
      }
    }

    .right {
      width: 50%;
      padding: 30px 30px;
      border: 1px solid $clr_gold;
      border-left: none;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      .room_name {
        color: $clr_gray_dark;
        font-size: 20px;
        text-transform: uppercase;
        margin-bottom: 5px;
      }

      .size_person {
        color: $clr_gray;
        font-size: 18px;
        margin-top: 5px;
      }

      .description {
        color: $clr_black_fliestext;
        line-height: 28px;
        margin: 15px 0 20px 0;
      }

      .room_link {
        text-decoration: none;
        text-transform: uppercase;
        color: $clr_gray_dark;

        &:hover{
          color: $clr_red;
          .icon{
            color: $clr_red;
          }
        }

        .icon {
          margin-left: 10px;
        }
      }

      .buttons {
        margin-top: 20px;

        a {
          text-decoration: none;
        }

        .button {
          @include button;
          padding: 12px 0;

          &.detail{
            background-color: transparent;
            color: $clr_black_fliestext;
            border: 1px solid $clr_black_fliestext;
            width: 100%;
            display: none;

            &:hover {
              background-color: $clr_black_fliestext;
              color: $clr_white;
            }
          }

          &.detailpage {
            width: 32%;
          }

          &.request {
            background-color: $clr_gray;
            color: $clr_white;
            border: 1px solid $clr_gray;
            width: 32%;

            &:hover {
              background-color: transparent;
              color: $clr_gray;
            }
          }

          &.book {
            background-color: $clr_turkey;
            color: $clr_white;
            border: 1px solid $clr_turkey;
            width: 32%;

            &:hover {
              background-color: transparent;
              color: $clr_turkey;
            }
          }
        }
      }
    }
  }
}

@media (max-width: 1200px) {
 .roomsList .room .left {
   .img-slider,
   .slick-track,
   .slick-slider div,
   .slider_item,
   img {
     max-height: 100%;
   }
 }
}

@media (max-width: 1024px) {
  .roomsList .room .right .buttons .button {
    padding: 15px 0;
  }
}

@media (max-width: 767px) {
  .roomsList {
    margin-top: -30px;
    .slick-dots{
      right: 50%;
      transform: translate(50%);
    }
    .room {
      display: block;
      .left {
        width: 100%;

        img{
          height: 155px;
        }

        .slick-slider{
          div{
            height: 135px;
          }
        }
        .icon-zoom{
          display: none;
        }
      }

      .right {
        width: 100%;
        padding: 10px;
        border: 1px solid $clr_gold;
        border-top: none;
        border-left: 1px solid $clr_gold;

        .description{
          display: none;
        }

        .room_name{
          font-size: 16px;
        }

        .buttons{
          .button{
            &.detail {
              display: block;
              margin-bottom: 5px;
            }

            &.request {
              width: 100%;
              background-color: $clr_gold;

              &:hover{
                background-color: transparent;
                color: $clr_gold;
              }
            }
            &.book{
              width: 100%;
              margin-top: 5px;
              display: none;
            }
          }
        }

        .room_link{
          display: none;
        }
      }
    }
  }

  .priceTab{
       display: none !important;
     }

  .priceTabMobile{
    display: block !important;
    background: $clr_red;
    width: 100%;
    height: 45px;
    color: $clr_white;
    padding: 8px 10px;

    .from,
    .person{
      text-transform: uppercase;
      font-size: 10px;
    }

    .price{
      font-size: 17px;
      font-family: Lora, sans-serif;
    }
  }
}
