.roomDetail {
  display: flex;
  flex-direction: column-reverse;
  margin-top: 10px;
  @include list-icon;
  .room_boxMobile{
    display: none;
  }

  .seasonSwitcher{
    margin-bottom: 35px;
    box-shadow: none;
  }

  .roomSlider{
    position: relative;
    .zimmerskizze{
      position: absolute;
      bottom: 30px;
      right: 20px;
      z-index: 1;
      background-color: rgba(255, 255, 255, 0.9);
      color: $clr_gray;
      padding: 15px;
      .button {
        border: none !important;
        text-decoration: none;
        padding: 20px 15px;
        @include button;
      }
    }
  }

  .border {
    border: 1px solid $clr_gold;
  }

  @include slick-dots;


  .slick-dots {
    position: absolute;
    bottom: 12px;
    left: 50%;
    transform: translate(-50%, 0);

    li {
      background-color: hsla(0, 0%, 100%, .5);
      button {
        background-color: $clr_white;
      }
    }
  }

  .left {
    .slick-slide {
      img {
        height: 580px;
      }
    }

    .icon-arrow_right1 {
      right: 0;
      position: absolute;
      top: 45%;
      z-index: 1;
      font-size: 33px;
      color: $clr_black;
      cursor: pointer;
      .bgR{
        height: 60px;
        width: 58px;
        background: #fff;
        margin-top: -47px;
        margin-left: 15px;
      }
      &:hover{
        color: $clr_red;
      }
    }

    .icon-arrow_left1 {
      left: 0;
      position: absolute;
      top: 45%;
      z-index: 1;
      font-size: 33px;
      color: $clr_black;
      cursor: pointer;
      .bgL{
        height: 60px;
        width: 58px;
        background: #fff;
        margin-top: -47px;
        margin-left: 0;
      }
      &:hover{
        color: $clr_red;
      }
      &:before{
        left: 30px;
        position: relative;
      }
    }

  }

  .right {
    display: flex;

    .priceBanner {
      width: 20%;
      .price_nights {
        width: 200px;
        height: 80px;
        background: $clr_red;
        margin-top: 25px;
        color: $clr_white;
        display: flex;
        justify-content: center;
        align-items: center;

        .price_nights_wrapper {
          padding: 14px 10px;

          .from {
            font-size: 14px;
            text-transform: uppercase;
          }

          .price {
            font-family: Lora, sans-serif;
            font-size: 22px;
            text-transform: uppercase;
          }

          .euro{
            font-size: 16px;
          }

          .person {
            font-size: 14px;
            text-transform: uppercase;
          }
        }
      }
    }
    .zimmerskizzeMobile{
      display: none;
    }
    .infoWrapper {
      width: 80%;
      margin-left: 40px;
      margin-right: 18.5%;
      .breadcrumb_new {
        font-size: 12px;
        color: #6A7474;
        text-transform: uppercase;
        margin-top: 25px;
        a {
          text-decoration: none;
          text-transform: uppercase;
          color: #6A7474;
        }
      }
      .room_name {
        font-size: 32px;
        color: $clr_red;
        font-family: Lora, sans-serif;
        text-transform: uppercase;
        letter-spacing: 1px;
      }

      .size_person {
        font-weight: $fw_bold;
        color: $clr_black_fliestext;
      }

      .description {
        margin-top: 25px;
        line-height: 28px;
      }

      .buttons {
        margin-top: 15px;
        text-align: center;
        a {
          text-decoration: none;
        }

        .button {
          @include button;

          &.detail {
            background-color: transparent;
            color: $clr_black_fliestext;
            border: 1px solid $clr_black_fliestext;
            width: 100%;
            display: none;

            &:hover {
              background-color: $clr_black_fliestext;
              color: $clr_white;
            }
          }

          &.request {
            background-color: $clr_gray;
            color: $clr_white;
            border: 1px solid $clr_gray;
            width: 30%;

            &:hover {
              background-color: transparent;
              color: $clr_gray;
            }
          }

          &.book {
            background-color: $clr_turkey;
            color: $clr_white;
            border: 1px solid $clr_turkey;
            width: 30%;

            &:hover {
              background-color: transparent;
              color: $clr_turkey;
            }
          }
        }
        &:first-child{
          margin-top: 30px;
        }
        &:last-of-type{
          margin-bottom: 30px;
          margin-top: 30px;
        }
      }

      .price_list {
        .no_rates{
          display: flex;
          justify-content: center;
          font-weight: bold;
        }
        .season_table {
          .price_table {
            @include table;
          }
        }
      }
    }
  }

  .backtoOverview{
    margin-top: 30px;
    text-align: center;
    .button {
      @include button;
      &:hover{
        @include buttonHover;
      }
    }
    .icon{
      display: none;
    }
  }
}

@media (max-width: 1024px) {
  .roomDetail {
    .right {
      .infoWrapper {
        .buttons {
          .button{
            padding: 15px 20px;
            &.request{
              width: 40%;
            }
            &.book{
              width: 40%;
            }
          }
        }
      }
    }
  }
}

@media (max-width: 767px){
  .roomDetail {
    margin-top: 22px;

    .seasonSwitcher{
      margin-bottom: 15px;
      margin-top: 0;
    }

    li{
      padding-left: 1.6em;
    }

    .roomSlider{
      .zimmerskizze{
        display: none;
      }
    }

    .border{
      border: none;
    }

    .img-slider{
      margin-top: 10px;
    }

    .room_boxMobile{
      display: block;

      .room_name {
        font-size: 20px;
        color: $clr_red;
        font-family: Lora, sans-serif;
        text-transform: uppercase;
        letter-spacing: 1px;
      }

      .breadcrumb_new{
        display: none;
      }

      .size_person {
        font-weight: $fw_bold;
        font-size: 14px;
        color: $clr_black_fliestext;
      }
    }

    .right{
      display: block;
      .priceBanner{
        width: 100%;

        .price_nights{
          width: 100%;
          margin-top: -5px;
          height: 45px;

          .price_nights_wrapper{
            padding: 9px 15px;

            .from,
            .person{
              font-size: 10px;
            }

            .price{
              font-size: 16px;

              .euro{
                font-size: 11px;
              }
            }
          }
        }
      }
      .zimmerskizzeMobile{
        display: block;
        text-align: right;
        width: 100%;
        margin: 10px 0;
        text-transform: uppercase;
      }
      .infoWrapper{
        margin: 0;
        width: 100%;
        padding: 0;

        .room_box{
          display: none;
        }

        .description{
          font-size: 14px;
          line-height: 21px;
          margin-top: 20px;
        }

        .buttons{
          .button{
            &.request{
              width: 100%;
            }
            &.book{
              width: 100%;
              margin-top: 5px;
              margin-bottom: 15px;
            }
          }
        }
        .buttons:last-of-type{
          margin-bottom: 20px;
        }
      }
    }

    .left{
      .slick-slide {
        img {
          height: 150px;
        }
      }
      .icon-arrow_left1{
        .bgL{
          width: 38px;
        }
        &:before{
          left: 7px;
          position: relative;
        }
      }
      .icon-arrow_right1{
        .bgR{
          width: 38px;
        }
      }
    }
    .backtoOverview{
      margin-top: 0;
      .button {
        @include button;
        border: 0;
        padding: 0;
        &:hover{
          text-decoration: underline;
          background-color: transparent;
          color: $clr_gray;
          border: 0;
          background-position: right;
        }
      }
      a{
        text-decoration: none;
      }
      .icon{
        display: inline-block;
        margin-left: 6px;
      }
    }
  }
}
