/*  ==========================================================================
    GENERAL
    Useful global helpers

    INFO:
    - use it with care, its not a trash can!
    - contributions for the kickstarter are welcome!
    ========================================================================== */

// clearfix
@mixin clearfix() {
  *zoom: 1;
  &:before,
  &:after {
    content: " ";
    display: table;
  }
  &:after {
    clear: both;
  }
}

// reset default button reset
@mixin resetHTMLButton() {
  appearance: none;
  background: none;
  box-shadow: none;
  border: 0;
  border-radius: 0;
  cursor: pointer;
  margin: 0;
  padding: 0;
  &:focus {
    outline: none;
  }
}

// responsive image
@mixin responsiveImg() {
  display: block;
  height: auto;
  width: 100%;
}

// cover image
@mixin coverImg() {
  display: block;
  height: auto;
  left: 50%;
  max-height: none;
  max-width: none;
  min-height: 100%;
  min-width: 100%;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  width: auto;
}

// cover image reset
@mixin coverImgReset() {
  display: initial;
  left: auto;
  max-height: 100%;
  max-width: 100%;
  min-height: 0;
  min-width: 0;
  position: static;
  top: auto;
  transform: translate(0, 0);
}

// create table
@mixin createTable() {
  display: table;
  > * {
    display: table-row;
    height: 100%;
    width: 100%;
    > * {
      display: table-cell;
      vertical-align: middle;
    }
  }
}

// reset table
@mixin resetTable() {
  display: initial;
  > * {
    display: initial;
    height: auto;
    width: auto;
    > * {
      display: initial;
      vertical-align: initial;
    }
  }
}

@mixin slick-dots{
  .slick-dots {
    *zoom: 1;
    display: flex;
    list-style-type: none;
    justify-content: center;
    padding: 10px 0;
    margin: 0 auto;
    flex-wrap: wrap;

    li {
      background-color: #B2B7B9;
      cursor: pointer;
      float: left;
      height: 5px;
      margin: 5px 10px 0 0;
      position: relative;
      width: 40px;

      &:hover{
        background-color: #DAA4A8;
      }

      button {
        box-shadow: none;
        border: 0;
        border-radius: 0;
        cursor: pointer;
        margin: 0;
        padding: 0;
        background-color: $clr_red;
        height: 100%;
        left: 0;
        max-width: 0;
        overflow: hidden;
        position: absolute;
        text-indent: -100px;
        top: 0;
        width: 100%;
        z-index: 5;

        &:hover{
          color: #DAA4A8;
        }
      }
    }
    .slick-active button {
      animation-direction: alternate;
      animation-duration: 6s;
      animation-timing-function: linear;
      animation-iteration-count: 1;
      animation-name: stretch;
      max-width: 100%;
    }
  }
}

@mixin list-icon{
  ul {
    list-style: none;
    padding: 0;
  }
  li {
    padding-left: 1.3em;
    position: relative;
  }
  li:before {
    content: "\e918";
    font-family: icomoon,sans-serif;
    color: #a21c26;
    font-size: 11px;
    padding-right: 1.1225em;
    position: absolute;
    top: 3px;
    left: 0.1em;
  }
}

@mixin table{
  table {
    border-collapse: collapse;
    width: 100%;
    border: 1px solid $clr_gold;
  }

  table td, table th {
    padding: 8px;
    width: auto;
    text-align: center;
    position: relative;
    vertical-align: middle;
  }

  table td p{
    text-align: center;
  }

  table td {
    border-right: 1px solid $clr_gold;
  }

  table tr:nth-child(even) {
    background-color: #EEEAE5;
  }
}
