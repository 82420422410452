.foldingBox{
  margin-top: 50px;
  &.noSpace{
    margin-top: 10px;
  }

  @include list-icon;

  .collapsible {
    background-color: #EEEAE5;
    color: $clr_gold;
    cursor: pointer;
    padding: 18px;
    width: 100%;
    border: none;
    text-align: left;
    outline: none;
    font-size: 22px;
    position: relative;
    padding-left: 30px;
    text-transform: uppercase;
    margin-top: 5px;
    .text{
      width: calc(100% - 40px);
    }
  }

  .active.collapsible{
    color: $clr_black_fliestext;
  }

  .collapsible:after {
    content: "\e91a";
    font-family: icomoon,sans-serif;
    font-size: 40px;
    float: right;
    margin-left: 5px;
    position: absolute;
    right: 20px;
    top: 9px;
    color: $clr_gold;
  }

  .active:after {
    content: "\e91b";
    font-family: icomoon,sans-serif;
  }

  .content {
    max-height: 0;
    overflow: hidden;
    transition: max-height 0.2s ease-out;
    background-color: #EEEAE5;
    .content_wrapper{
      padding: 0 30px 30px 30px;
    }
  }
}

@media (max-width: 1024px){
  .collapsible {
    font-size: 18px !important;
    line-height: 25px;
    padding: 13px !important;

    &:after{
      padding: 4px;
    }
  }
  .content{
    font-size: 15px;
  }
}

@media (max-width: 767px){
  .collapsible {
    font-size: 13px !important;
    line-height: 15px;
    padding: 13px !important;

    &:after{
      padding: 4px;
      font-size: 27px !important;
    }
  }

  .content{
    font-size: 12px;
  }
}