.contactbox{
  text-align: center;
  margin-bottom: 70px;
  margin-top: 50px;
  line-height: 25px;
  .hotel{
    font-weight: $fw_bold;
  }

  a{
    text-decoration: none;
    color: $clr_red;
    &:hover{
      color: $clr_gold;
    }
  }

  .signet{
    color: $clr_red;
    font-size: 40px;
    margin-bottom: 20px;
  }
}

//RESPONSIVE

@media (max-width: 1024px) {
  .contactbox{
    font-size: 12px;
  }

}
@media (max-width: 767px) {
  .contactbox{
    font-size: 13px;
    line-height: 18px;
    margin-bottom: 50px;
    margin-top: 25px;
  }
}