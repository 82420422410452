/*  ==========================================================================
    GOOGLE MAPS
    ========================================================================== */

.google_maps-CE {
  margin-top: 50px;
  .route_calc {
    background: rgba(173, 152, 129, 0.15);
    padding: 30px 0;

    .input_row {
      display: flex;
      justify-content: center;
      padding-bottom: 15px;

      input {
        padding: 5px;
      }
    }
    .button_row {
      display: flex;
      justify-content: center;

      .button {
        @include button;
        cursor: pointer;
        &:hover{
          @include buttonHover
        }
      }
    }
  }
  .content_gmaps_container {
    height: 560px;
  }
}

/* RESPONSIVE BEHAVIOR
 * --------------------------------------------------------------------------- */
@media (max-width: 999px) {
  .google_maps-CE .content_gmaps_container {
    height: 400px;
  }
}
@media (max-width: 767px) {
  .google_maps-CE .content_gmaps_container {
    height: 350px;
  }
}