.todoBox .wrapper {
  display: flex;
  justify-content: space-between;
  margin-top: 50px;
  flex-flow: wrap;
  .box {
    position: relative;
    max-height: 250px;
    overflow: hidden;
    margin-top: 10px;

    &:first-of-type {
      background-color: $clr_red;
      color: $clr_white;
      padding-left: 40px;
      display: flex;
      flex-direction: column;
      justify-content: center;
    }

    &.slide:first-of-type {
      padding-left: 0;
      padding-top: 0;
    }

    &:first-of-type,
    &:nth-child(2) {
      width: 49.5%;
      margin-top: 0;
    }

    &:nth-child(3) {
      width: 25%;
    }

    &:nth-child(4),
    &:nth-child(5) {
      width: 36.5%;
    }

    a{
      text-decoration: none;
      display: flex;
      justify-content: flex-start;
      align-items: center;
    }

    .button {
      display: flex;
      justify-content: center;
      align-items: center;
      border: 1px solid $clr_white;
      text-transform: uppercase;
      padding: 15px 40px;
      color: $clr_white;
      cursor: pointer;
      background: linear-gradient(to left, transparent 50%, $clr_white 50%);
      background-size: 201% 100%;
      background-position: right bottom;
      transition: all ease .3s;
      margin-top: 20px;
      max-width: 200px;
      &:hover {
        background-position: left bottom;
        color: $clr_red;
      }
    }

    .header {
      text-transform: uppercase;
      font-size: 30px;
      color: $clr_white;
    }

    .subheader {
      text-transform: uppercase;
      color: $clr_white;
    }

    .boxIcon {
      font-size: 40px;
      color: $clr_white;
    }

    .titleWrapper {
      position: absolute;
      width: 100%;
      height: 100%;
      padding: 10px;

      .title {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 100%;
        color: $clr_white;
        text-transform: uppercase;
        font-size: 20px;
        position: relative;
        z-index: 2;
      }
    }

    img {
      height: 100%;
      transform: translateX(-50%);
      margin-left: 50%;
    }

    .overlay {
      background: $clr_black;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      opacity: 0.3;
      z-index: 1;
      transition: 0.5s;
    }

    &:hover {
      .title {
        height: auto;
        top: 78%;
      }

      .overlay {
        top: initial;
        bottom: 20px;
        height: 20%;
        transition: 0.5s;
        width: 95%;
        left: 50%;
        transform: translate(-50%);
        opacity: .6;
      }
    }

  }
}

//RESPONSIVE
@media (max-width: 1024px) {
  .todoBox .wrapper {
    .box {
      max-height: 200px;
      .header {
        font-size: 16px;
      }

      .titleWrapper .title {
        font-size: 13px;
      }

      .subheader {
        font-size: 10px;
      }

      .boxIcon {
        font-size: 30px;
      }
    }
  }
}

@media (max-width: 767px) {
  .todoBox {
    margin-bottom: 20px;
    .wrapper {
      margin-top: 25px;
      .box:first-of-type {
        width: 100%;
        padding-top: 15px;
        padding-left: 0;
        text-align: center;
        padding-bottom: 20px;

        .boxIcon {
          font-size: 20px;
        }

      }
      .box {
        .header {
          margin-top: 5px;
        }

        .button {
          padding: 12px 90px;
        }
        a{
          display: flex;
          justify-content: center;
          align-items: center;
        }
      }
    }
    .wrapper2 {
      display: flex;
      justify-content: space-between;
      flex-flow: wrap;
      .box {
        position: relative;
        max-height: 250px;
        overflow: hidden;

        .titleWrapper {
          position: absolute;
          width: 100%;
          height: 100%;
          padding: 10px;

          .title {
            display: flex;
            justify-content: center;
            align-items: center;
            height: 100%;
            color: $clr_white;
            text-transform: uppercase;
            font-size: 15px;
            position: relative;
            z-index: 2;
            text-align: center;
            padding: 0 25px;
          }
        }

        img {
          height: 220px;
        }

        .overlay {
          background: $clr_black;
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          opacity: 0.3;
          z-index: 1;
        }
      }
    }

    .icon-arrow_right1 {
      right: 0px;
      position: absolute;
      top: 38%;
      z-index: 1;
      font-size: 33px;
      cursor: pointer;
      .bgR{
        height: 60px;
        width: 35px;
        background: #fff;
        margin-top: -47px;
        margin-left: 15px;
      }
      &:hover{
        color: $clr_red;
      }
    }

    @include slick-dots;

    .icon-arrow_left1 {
      left: 0px;
      position: absolute;
      top: 38%;
      z-index: 1;
      font-size: 33px;
      cursor: pointer;
      .bgL{
        height: 60px;
        width: 50px;
        background: #fff;
        margin-top: -47px;
        margin-left: -20px;
      }
      &:hover{
        color: $clr_red;
      }
    }
  }
}