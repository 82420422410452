/*  ==========================================================================
    BUTTONS
    ========================================================================== */
@mixin button {
  border: 1px solid $clr_gray;
  text-transform: uppercase;
  padding: 12px 40px;
  color: $clr_gray;
  cursor: pointer;
  background: linear-gradient( to left, transparent 50%,     $clr_gray 50% );
  background-size: 201% 100%;
  background-position: right bottom;
  transition: all ease .3s;
}

@mixin buttonHover {
  //background-color: $clr_gray;
  //color: white;
  //border: 1px solid $clr_gray;
  background-position: left bottom;
  color: white;
}