.iframe_16x9 {
  > div {
    position: relative;
    height: 0;
    padding-bottom: 56.2%;
    iframe {
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      border: none;
      width: 100%;
      height: 100%;
    }
  }
}