/*  ==========================================================================
    MAIN
    This stylings will be loaded dynamically by the "inline.js"

    INFO:
    - don't define scss rules here, just @import the appropriate files
    - generated file will be found here: "/Resources/Public/admin/css/main.css"
    ========================================================================== */

/*
 * variables and mixins
 */

@import 'variables/variables';
@import 'mixins/mixins';

/*
 * custom files
 */

@media screen {
  @import 'modules/lib/modules';
  @import 'layout/footer';
}
