/*  ==========================================================================
    MODULES
    Collection of all modules (except those which should be integrated 'inline')
    ========================================================================== */

// std gallery
@import 'std-element';
@import "google_maps";
@import 'gallery_slider';
@import 'eh_offer_slider';
@import 'eh_offer_list';
@import 'eh_offer_detail';
@import 'eh_rooms_list';
@import 'eh_roomdetail';
@import 'foldingbox';
@import 'quote';
@import 'header_button';
@import "quickrequest";
@import 'erlebnisslider';
@import 'doublebox';
@import "todo";
@import "textimagebox";
@import "contactbox";
@import "interestingPages";
@import "rs-review";
@import "iframe_16x9";
@import "banner";