/*  ==========================================================================
    STD ELEMENT
    ========================================================================== */

.std_element {
  margin-top: 50px;

  &.firstElement{
    margin-top: 5px;

    .button {
      position: relative;
      left: 50%;
      transform: translateX(-50%);
    }
  }

  @include list-icon;

  .firstElement {
    h1,
    h2 {
      text-align: center;
    }
  }

  .std_text.firstElement{
    text-align: center;
  }

  h1{
    color: $clr_red;
    font-size: 40px;
    font-family: $ff_lora;
    line-height: 51px;
    padding-bottom: 10px;
    font-weight: $fw_regular;
  }

  h2{
    color: $clr_gold;
    font-size: 22px;
    font-family: $ff_cantarell;
    line-height: 35px;
    padding-bottom: 10px;
    font-weight: $fw_regular;
    text-transform: uppercase;
  }

  .std_text{
    color: $clr_black_fliestext;
    line-height: 28px;
  }

  .button{
    @include button;
    text-decoration: none;
    margin-top: 10px;
    &:hover{
      @include buttonHover;
    }
  }

  .readMoreLink{
    text-decoration: none;
    color: $clr_black_fliestext;
    display: flex !important;
    flex-direction: column;
    text-transform: uppercase;

    &:active,
    &:hover{
      color: #A5A7A8;
    }
  }
}


/* RESPONSIVE BEHAVIOR
 * --------------------------------------------------------------------------- */

@media (max-width: 1024px){
  .std_element{
    margin-top: 25px;
    h1{
      font-size: 25px;
      line-height: 35px;
    }

    h2{
      font-size: 18px;
      line-height: 25px;
    }

    .std_text{
      font-size: 15px;
      line-height: 25px;
    }
  }
}

@media (max-width: 767px){
  .std_element{
    &.firstElement{
      margin-top: 0;
    }
    margin-top: 25px;
    h1{
    font-size: 19px;
      line-height: 24px;
    }

    h2{
      font-size: 15px;
      line-height: 23px;
    }

    .std_text{
      font-size: 12px;
      line-height: 19px;
      overflow: hidden;
      position: relative;
      &[aria-expanded^=false]:after{
        display: block;
        content: "";
        width: 100%;
        height: 55px;
        background-image: -webkit-gradient(linear,left top,left bottom,from(rgba(26,23,27,0)),color-stop(25%,rgba(26,23,27,0)),color-stop(95%,#fff));
        position: absolute;
        bottom: 0;
        left: 0;
        pointer-events: none;
      }
    }

    .readMoreLink{
      font-size: 12px;
    }
  }
}
