.doubleBox {
  display: flex;
  justify-content: space-between;
  margin-top: 50px;
  .box {
    width: 49%;
    position: relative;
    max-height: 250px;
    overflow: hidden;

    img{
      height: 250px;
    }

    .titleWrapper {
      position: absolute;
      width: 100%;
      height: 100%;
      padding: 10px;

      .title {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 100%;
        border: 1px solid $clr_white;
        color: $clr_white;
        text-transform: uppercase;
        font-size: 25px;
        position: relative;
        z-index: 1;
      }

      .linie {
        color: $clr_white;
        font-size: 30px;
        top: 50%;
        position: absolute;
        left: 0;
        right: 0;
        margin-left: auto;
        margin-right: auto;
        margin-top: 10px;
        width: 130px;
        z-index: 1;
      }
    }

    .overlay {
      background: $clr_black;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      opacity: 0.3;
      transition: 0.25s;
    }

    &:hover{
      .overlay {
        background: $clr_red;
        opacity: 0.85;
      }
    }
  }
}

//RESPONSIVE
@media (max-width: 1024px) {
  .doubleBox {
    .box {
      .titleWrapper {
        .title {
          font-size: 20px;
        }
      }
    }
  }
}

@media (max-width: 767px) {
  .doubleBox {
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: auto;
    margin-top: 35px;
    padding: 0;
    .box {
      width: 100%;
      height: 124px;
      &:nth-child(2){
        margin-top: 10px;
      }
      img{
        height: 124px;
      }
      .titleWrapper{
        .title{
          font-size: 16px;
        }
        .linie{
          margin-top: 0;
        }
      }
    }
  }
}