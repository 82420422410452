.interestingPages{
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  a{
    color: #626262;
    font-size: 14px;
    text-decoration: none;
    margin-left: 5px;
    &:hover{
      color: $clr_gold;
    }
  }
  .interestingElement{
    color: #626262;
    font-size: 14px;
    &:after{
      content: " | ";
    }
    &:last-of-type,
    &:first-of-type{
      &:after {
        content: '';
      }
    }
  }
}

@media (max-width: 1024px){
  .interestingPages{
    padding-bottom: 30px;
  }
}
@media (max-width: 767px){
  .interestingPages{
    line-height: 17px;
    a,
    .interestingElement{
      font-size: 10px;
    }
  }
}